
import {  IonList,IonLabel, IonSegment, IonItem,IonCardHeader,IonSegmentButton, IonCardTitle, IonCard, IonCardSubtitle,IonText, IonButton, IonGrid, IonRow, IonCol } from '@ionic/vue';
import { chevronUpOutline, calendarOutline, compassOutline, shareSocialOutline, pricetagsOutline } from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import MemberItem from "@/components/item/MemberItem.vue";
import DetailCard from "@/components/card/DetailCard.vue";
import { ref} from "vue";

export default {
  name: 'Event',
  components:{
    BaseLayout,
    IonList,
    IonCardTitle,
    IonCardSubtitle,
    MemberItem,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    DetailCard,
    IonText,
    IonCard,
    IonLabel,
    IonItem,
    IonCardHeader
  },
  props:['id'],
  created() {
    this.loadEvent();
    },
  setup(){
    const content = ref();
    const scrollTop = () => {
      content.value.$el.scrollToPoint(0, 100, 1000);
    }
    return {
      chevronUpOutline,
      calendarOutline,
      compassOutline,
      shareSocialOutline,
      pricetagsOutline,
      scrollTop,
      content
    }
  },
  computed:{
    event(){
      return this.$store.getters.event;
    },
    signup(){
      return {
        going: this.$store.getters.joinedEvent,
        action:'event'};
    }
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.active = ev.detail.value;
      // console.log('Segment changed', ev.detail.value);
    },
    loadEvent(){
      return this.$store.dispatch('event', this.$route.params.id );
    },
    externalLink(){
      window.open(this.event.website, '_system');
    }

  },
  data(){
    return{
      active:'details',
      fab:false,
    }
  }
}

